<template>
  <div v-if="newsList.length > 0" class="news-list-mobile d-lg-none">
    <container class="pt-4 pb-4">
      <div class="row">
        <div class="col-lg-5 offset-lg-1 col-12">
          <div class="h2 mb-lg-4">{{ $texts('news', 'News') }}</div>
          <ul>
            <li v-for="news in newsList" :key="news.nid" class="entry">
              <figure v-if="newsImageUrl(news)" class="snippet-image">
                <div class="book-cover">
                  <DrupalLink class="" :to="news?.url?.path">
                    <img :src="newsImageUrl(news)" alt="" title="" />
                  </DrupalLink>
                </div>
              </figure>
              <div class="font-weight-semibold">
                {{ news?.date?.value }}
              </div>
              <DrupalLink class="" :to="news?.url?.path">
                <div class="title">{{ news.title }}</div>
                <p v-if="news.teaser" class="teaser">{{ news.teaser }}</p>
              </DrupalLink>
            </li>
          </ul>
        </div>
      </div>
    </container>
  </div>
</template>

<script setup lang="ts">
import type { NodeNewsFragment } from '#graphql-operations'

defineProps({
  newsList: {
    type: Array as PropType<NodeNewsFragment[]>,
    default: () => [],
  },
})

const { $texts } = useNuxtApp()

// Methods
const newsImageUrl = function (news: NodeNewsFragment) {
  if (news && news.media && news.media.image && news.media.image.url) {
    return news.media.image.url.url
  }
}
</script>

<style scoped lang="scss">
@import '~/assets/scss/variables';
.news-list-mobile {
  background: $gray-400;

  ul {
    list-style: none;
    padding: 0;
    li {
      margin: 0;
      list-style: none;
      margin-bottom: 1rem;
    }
  }

  .snippet-image {
    width: 8rem;
  }

  .title {
    margin-bottom: 0.5rem;
  }

  .teaser {
    font-size: 1rem;
  }
}
</style>
